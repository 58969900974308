import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  ChakraProvider,
  extendTheme,
  Box,
  VStack,
  HStack,
  Text,
  Button,
  Input,
  Image,
  useToast,
  StackDivider,
  Divider,
  Flex,
} from "@chakra-ui/react";
import { ethers } from "ethers";
import contractABI from "./abi.json";
import logo from "./assets/logo.png";
import eve from "./assets/eve.png";
import dogechain from "./assets/dogechain.png";
import eden from "./assets/eden.png";
import polygon from "./assets/polygon.png";
import guard from "./assets/guard.png";
import 'core-js/stable'; 
import 'regenerator-runtime/runtime'; 
import "./App.css";

const theme = extendTheme({
  colors: {
    bitcoinOrange: {
      500: "#F7931A",
      100: "#FFECDB",
    },
    pixelatedWhite: "#FFFFFF",
  },
  fonts: {
    body: "Comic Neue, sans-serif",
  },
});

function App() {
  const toast = useToast();
  const [walletAddress, setWalletAddress] = useState(null);
  const [totalSupply, setTotalSupply] = useState("");
  const [dogedBalance, setDogedBalance] = useState("");
  const [stakeAmount, setStakeAmount] = useState("");
  const [unstakeAmount, setUnstakeAmount] = useState("");
  const [loadAmount, setLoadAmount] = useState("");
  const contractAddress = "0x780a053f5bf4bf47d42b1ce40fd54b7d61c40f80";
  const [contributors, setContributors] = useState([]);


  const provider = useMemo(() => new ethers.providers.Web3Provider(window.ethereum, "any"), []);
  const contract = useMemo(() => new ethers.Contract(contractAddress, contractABI, provider.getSigner()), [provider]);

  const fetchContractData = useCallback(async () => {
    if (!walletAddress || !contract) return;

    try {
      const supply = await contract.totalSupply();
      const balance = await contract.balanceOf(walletAddress);
      setTotalSupply(ethers.utils.formatEther(supply));
      setDogedBalance(ethers.utils.formatEther(balance));
    } catch (error) {
      console.error("Error fetching contract data:", error);
    }
  }, [walletAddress, contract]);

 
  // Add tracking script
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
      (function() {
        window.__insp = window.__insp || [];
        __insp.push(['wid', 374796675]);
        var ldinsp = function(){
          if(typeof window.__inspld != "undefined") return; window.__inspld = 1; var insp = document.createElement('script'); insp.type = 'text/javascript'; insp.async = true; insp.id = "inspsync"; insp.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://cdn.inspectlet.com/inspectlet.js?wid=374796675&r=' + Math.floor(new Date().getTime()/3600000); var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(insp, x); };
          setTimeout(ldinsp, 0);
      })();
    `;
    document.head.appendChild(script);
  
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    fetchContractData();
  }, [fetchContractData]);

  useEffect(() => {
    const fetchContributors = async () => {
      try {
        const response = await fetch('YOUR_API_ENDPOINT');
        if (!response.ok) {
          throw new Error('Failed to fetch contributors');
        }
        const data = await response.json();
        setContributors(data);
      } catch (error) {
        console.error('Error fetching contributors:', error.message);
      }
    };
  
    fetchContributors();
  }, []); 

  const connectWallet = async () => {
    try {
      const accounts = await provider.send("eth_requestAccounts", []);
      setWalletAddress(accounts[0]);
      fetchContractData();
    } catch (error) {
      console.error("Error connecting to wallet:", error);
    }
  };

  const disconnectWallet = () => {
    setWalletAddress(null);
    setDogedBalance("");
    setTotalSupply("");
  };

  const stakeNow = async () => {
    if (!stakeAmount) return;
    try {
      const tx = await contract.stake({ value: ethers.utils.parseEther(stakeAmount) });
      await tx.wait();
      fetchContractData();
      toast({
        title: "Stake Successful",
        description: `You have staked ${stakeAmount} DOGED.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Staking error:", error);
    }
  };

  const unstakeNow = async () => {
    if (!unstakeAmount) {
      toast({
        title: "Unstake Failed",
        description: "Please enter an amount to unstake.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return; // Exit if no amount is entered.
    }
  
    try {
      const unstakeAmountFormatted = ethers.utils.parseUnits(unstakeAmount, 18);
  
      const tx = await contract.unstake(unstakeAmountFormatted);
      await tx.wait(); // Wait for the transaction to be confirmed.
  
      fetchContractData();
      toast({
        title: "Unstake Successful",
        description: `You have successfully unstaked ${unstakeAmount} DOGED.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Unstaking error:", error);
      toast({
        title: "Unstake Failed",
        description: `Error: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  
    setUnstakeAmount('');
  };

  const loadContract = async () => {
    if (!loadAmount) return;
    try {
      const tx = await contract.loadContractWithCollateral({ value: ethers.utils.parseEther(loadAmount) });
      await tx.wait();
      fetchContractData();
      toast({
        title: "Contract Loaded",
        description: `You have loaded the contract with ${loadAmount} ETH.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Loading contract error:", error);
    }
  };

  return (

<ChakraProvider theme={theme}>
<VStack spacing={4} align="stretch" bg="white" p={4} w="90%" maxW="1200px" m="auto">
     
      {/* HEADER */}
      <HStack justifyContent="space-between" p={4}>
        <Button
          colorScheme="red"
          onClick={walletAddress ? disconnectWallet : connectWallet}
        >
          {walletAddress ? "Disconnect" : "Connect Wallet"}
        </Button>
        {walletAddress && <Text fontSize={["md", "lg"]}>🐕 DOGED Balance: {dogedBalance}</Text>}
      </HStack>

      {/* CONTENT */}
      <Flex direction={["column", "row"]} spacing={4} wrap="wrap">
        {/* Left Side */}
        <VStack p={6} spacing={4} flex="1">
          <Image src={logo} boxSize={["100px", "150px"]} alignSelf="left" />
          <Text fontSize={["lg", "xl"]} fontWeight="bold">
          MUCH WOW! $DOGED<br />Doge Dollar Bringing Stablility to the Moon! 🌕
          </Text>
          <Text fontSize={["sm", "md"]}>
          $DOGED "Doge Dollar" introduces stability like never before found on the other guys. $DOGED maintains a <u>steady value of $1.00</u>, backed by our beloved DOGECOIN. This stability is ensured through a <a href="https://feeds.witnet.io/dogechain" target="_blank" rel="noopener noreferrer" title="WitNet doge price oracle">decentralized price oracle</a>, providing transparency and reliability.
          </Text>
          <Text fontSize={["sm", "md"]}>
          $DOGED goes beyond the norm with its groundbreaking "Volatility Dampener," a game-changing mechanism, dynamically adjusting minting and burning processes, and collateralization with our beloved DOGECOIN, making it the world's premier and first stablecoin backed by DOGECOIN. With a <u>3% reflection</u> and opportunities for users to contribute and stake securely, $DOGED offers a stable and innovative experience.
          </Text>
          <Text fontSize={["sm", "md"]}>
          Its recent integration into <a href="http://dogeguard.dog" target="_blank" rel="noopener noreferrer" title="GUARD zkEVM multichain experience">dogeguard.dog</a> opens up endless possibilities for users. Join the $DOGED revolution today and experience the future of community firsthand.
        </Text>

  <Text flex="1" fontSize="sm" textAlign="center" paddingTop="4">
    <a href="https://dogeguard.dog" target="_blank" rel="noopener noreferrer" title="GUARD zkEVM multichain app">
      <Image src={guard} boxSize="239px" maxHeight="50px" objectFit="contain" />
    </a>
<br />
    <a href="http://dogeguard.dog" target="_blank" rel="noopener noreferrer" title="GUARD zkEVM multichain experience">Download App.</a>
  </Text>
</VStack>

        <Divider orientation="vertical" display={["none", "none", "block"]} borderColor="gray.200" />

        {/* Right Side */}
<VStack spacing={4} p={8} flex="1" align="stretch" borderLeft={["none", "none", "1px"]} borderColor={["", "", "gray.200"]}>
<VStack spacing={2} align="start">
    <Text>$DOGED: <a href="https://explorer.dogechain.dog/token/0x780A053F5bF4Bf47D42b1CE40fD54b7d61c40F80/" target="_blank" rel="noopener noreferrer" title="View DOGED on Explorer">0x780A053F5bF4Bf47D42b1CE40fD54b7d61c40F80</a></Text>
    <Text>Total Supply of $DOGED: {totalSupply || "please connect wallet"}</Text>
  </VStack>
  {/* Stake Section */}
  <Box bg="gray.100" p={4} borderRadius="lg">
  <VStack spacing={2} align="stretch">
  <Text fontSize="lg" align="left" fontWeight="bold" pt={4}>🌱⏳ Stake $wDOGE & Receive $DOGED
  </Text>
    <Input
      placeholder="Amount in wDOGE"
      value={stakeAmount}
      onChange={(e) => setStakeAmount(e.target.value)}
      size="md"
      bg="white"
      borderColor="orange.500"
    />
    <Button
      colorScheme="orange"
      onClick={stakeNow}
      width="full" 
      variant="solid"
    >
      Stake
    </Button>
  </VStack>
  {/* Unstake Section */}
  <VStack spacing={2} align="stretch">
  <Text fontSize="lg" align="left" fontWeight="bold" pt={4}>🔓🌱 Unstake $DOGED & Receive $wDOGE
  </Text>
    <Input
      placeholder="Amount in DOGED"
      value={unstakeAmount}
      onChange={(e) => setUnstakeAmount(e.target.value)}
      size="md"
      bg="white"
      borderColor="orange.500"
    />
    <Button
      colorScheme="orange"
      onClick={unstakeNow}
      width="full" 
      variant="solid"
    >
      Unstake
    </Button>
  </VStack>
  </Box>

<Divider borderColor="gray.200" />

   {/* Load Collateral Section */}
   <Box bg="#001f3f" p={4} borderRadius="lg">
          <VStack spacing={2} align="stretch">
            <Text fontSize="xl" fontWeight="bold" color="white">🌱🤝 Contribute to Stability of $DOGED</Text>
            <Input
              placeholder="contribute wDOGE"
              value={loadAmount}
              onChange={(e) => setLoadAmount(e.target.value)}
              size="md"
              bg="white"
              borderColor="white"
            />
            <Button
              onClick={loadContract}
              width="full"
              variant="outline"
              bg="white"
              colorScheme="black"
            >
              Load
            </Button>
            <Text color="white">Thank you for being a vital part of the $DOGED community! Your contribution fuels our mission to create a more decentralized and inclusive future. Together, we're fostering a sense of freedom and empowerment. So, let's explore new horizons and embrae the endless possibilities. Your support is invaluable, and together, we'll continue to push the boundaries of what's possible. #muchlove
</Text>
          </VStack>
        </Box>
      </VStack>
      </Flex>
{/* COSMIC PARTNERS */}
<VStack spacing={4} justifyContent="space-between" wrap="wrap">
  <Text fontSize="xl" fontWeight="bold" pt={4}>HONOURABLE MENTIONS</Text>
  <HStack spacing={4} align="stretch">
  <a href="http://dogelovestory.dog" target="_blank" rel="noopener noreferrer" title="EVE zkEVM multichain bridge"><Image src={eve} boxSize="180px" maxHeight="100px" /></a>
  <a href="http://dogechain.dog" target="_blank" rel="noopener noreferrer" title="dogechain.dog"><Image src={dogechain} boxSize="180px" maxHeight="100px" /></a>
  <a href="http://eden.dog" target="_blank" rel="noopener noreferrer" title="zkEVM multichain Oasis"><Image src={eden} boxSize="180px" maxHeight="100px" /></a>
  <a href="https://polygon.technology/polygon-zkevm" target="_blank" rel="noopener noreferrer" title="GUARD zkEVM multichain experience"><Image src={polygon} boxSize="180px" maxHeight="100px" /> </a>
  </HStack>
</VStack>
      {/* Footer */}
      <Text fontSize={["sm", "md"]} textAlign="center" p={4}>
      Powered by YOU. Doge Dollar began its voyage on April 2023 – Destination, a world built on trust, innovation, and shared love of DOGECOIN.</Text>
      <Text fontSize={["sm", "md"]} textAlign="center" p={4}>
      While $DOGED strives to provide stability with its impressive features, it's crucial to acknowledge that all endeavors entail risks. So, as you set forth on this adventure, equip yourself with the necessary tools and stay vigilant. Do Your Own Research (DYOR)</Text>
    </VStack>
  </ChakraProvider>
);
}

export default App;